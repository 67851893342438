import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";
import SleepyNewsAnchor from "../../components/SleepyNewsAnchor";

const NewsPage = () => (
	<Layout>
		<Seo title="June 2022" />
		<NewsHeader />
		<h2>June 2022</h2>

		<h3 className="blogdate">
			Thursday, June 16, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="3">Canada shows in August?</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Maybe! Possibly!
				Can you help make it happen?
				I'm aiming for Montreal, the Okanagan, and Hamilton (Ontario).
				Please see <Link to="/l/summer2022/">https://www.jesserivest.com/l/summer2022/</Link> and
				connect with me if you're interested or can help!
			</p>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="https://www.youtube-nocookie.com/embed/veuTCPPqZpc"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />
		</div>

		<h3 className="blogdate">
			Thursday, June 9, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="2">Nostargic lyric video is up</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				A little video made in the field at the end of my neighbourhood in Brasilia,
				<span className="italic">Nostargic</span>—lyric video is now available for viewing.
				Just head over to the Extras section at my <Link to="/music/11/">music page</Link> to see it!
			</p>
		</div>

		<h3 className="blogdate">
			Thursday, June 2, 2022
		</h3>

		<h4 className="blogitemtitle">
			<SleepyNewsAnchor num="1">Nostargic (new single) drops today</SleepyNewsAnchor>
		</h4>
		<div className="blogitembody">
			<p>
				Shooting now across your device screen—just appearing now in the digital sky as you read this—my latest song,
				Nostargic, is out there, sparkling and shining to catch your eye... umm, ears.
			</p>
			<p>
				🎶 <span className="italic">I go back in time the odd night... blinking at the sky</span> 🎶
			</p>
			<p>
				Please find it wherever you prefer to stream or buy digital music.
				You might do that while also reading about it at my  <Link to="/music/11/">music page</Link>.
			</p>
			<p>
				🎶 <span className="italic">If I could I'd find the old spark... blow it back to life</span> 🎶
			</p>
			<div className="main--centerwrapper p">
				<StaticImage
					src="../../images/catalog artwork/JRIVEST11 nostargic front.jpg"
					alt="Jesse Rivest - Nostargic - cover art"
					placeholder="blurred"
					layout="constrained"
					className="main--centered"
					imgClassName="img--bordered-small"
					width={300}
				/>
			</div>
		</div>

		<p>
		<Link to="/news/">Go back to the news index page</Link>
		</p>
	</Layout>
);

export default NewsPage;
